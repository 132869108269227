<template>
  <navbar v-if="displaySection">
    <template #logo>
      <!-- logo  -->
      <img src="@/assets/img/logo.svg">
      <h3>{{company.brand}}</h3>
    </template>
  </navbar>
  <main>
    <router-view/>
  </main>
  <Footer v-if="displaySection"/>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import company from '@/data/company.json';
import { createAppendScript } from '@core/composables/utilities';

const route = useRoute();

const routeToHide = ['Payment'];
const displaySection = computed(() => {
  return !routeToHide.includes(route.name);
});

/**
 * Add custom script in the APP
*/
const envMod = process.env.NODE_ENV;
switch (envMod) {
  case 'production':
    createAppendScript('../assets/js/new_relic.js');
    createAppendScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TcrYnT');
    break;
  case 'development':
    createAppendScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=Sfvy9P');
  break;
  default:
    break;
}
</script>
