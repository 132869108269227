var logData = '';

/**
 * Log Data after disconnected
 * @param {*} paymentIntent | object from stripeStore.retrievePaymentIntent()
 */
const nrLogPaymentStatus = (paymentIntent) => {
    logData = { ...logData, 'Payment ID': paymentIntent.id, 'Payment Status': paymentIntent.status };
    if (typeof newrelic == 'object') window.newrelic.addPageAction('PAYMENT', logData);
}

const nrLogs = (eventName, data) => {
    if (typeof newrelic == 'object') window.newrelic.addPageAction(eventName, data);
}

export {
    nrLogPaymentStatus,
    nrLogs
}